import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	Grid,
	LinearProgress,
	SxProps,
	Theme,
} from '@mui/material';
import { ActivityProp } from './ActivityProp';

const sx_card: SxProps<Theme> = {
	border: '4px solid cornflowerblue',
	margin: '6px',
	width: '230px',
};

const sx_card_button: SxProps<Theme> = {
	padding: '12px',
	border: '3px solid skyblue',
	borderRadius: 3,
	margin: '8px',
	width: '200px',
	height: '60px',
};

const sx_card_progress: SxProps<Theme> = {
	width: '90%',
};

const sx_card_content: SxProps<Theme> = {
	padding: '8px',
	width: '214px',
};

const tick = 100;

export const ActivityCard: React.FC<ActivityProp> = (props) => {
	const {
		buttonName,
		displayChildren,

		isActive,
		isWork,

		startTime,
		workTime,

		onClick,
	} = props;

	// workTime -> second
	const maxProgress = workTime * 1000;
	const progress_start = isWork ? (Date.now() - startTime) % maxProgress : 0;

	const [progress, setProgress] = useState(progress_start);

	useEffect(() => {
		if (!isWork) {
			setProgress(0);
			return;
		}

		const timer = setInterval(() => {
			setProgress(old => {
				return (old + tick) % maxProgress;
			});
		}, tick);

		return () => {
			clearInterval(timer);
		};
	}, [isWork, maxProgress]);

	return (
		<Card sx={{ ...sx_card, ...(isWork ? { backgroundColor: '#dcf0fa' } : {}) }}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
			>
				<Button disabled={!isActive} sx={sx_card_button} onClick={onClick}>
					{buttonName}
				</Button>
				<Box sx={sx_card_progress}>
					<LinearProgress
						variant='determinate'
						sx={{ '& .MuiLinearProgress-bar': { transition: progress <= tick ? 'none' : 'transform 0.2s linear' } }}
						value={Math.floor((progress / maxProgress) * 100)}
					/>
				</Box>
				<Box sx={sx_card_content}>
					{displayChildren}
				</Box>
			</Grid>
		</Card>
	);
};
