import React from 'react';
import { Grid, SxProps, Theme } from '@mui/material';
import {
	MainSideBarCertCard,
	MainSideBarStatusCard,
	MainSideBarUpgradeCard,
} from './sidebar';

const sx_grid: SxProps<Theme> = {
	width: 400,
};

export const MainSideBar: React.FC = () => {
	return (
		<Grid
			container
			direction='column'
			justifyContent='flex-start'
			alignItems='center'
			sx={sx_grid}
		>
			<MainSideBarStatusCard />
			<MainSideBarUpgradeCard />
			<MainSideBarCertCard />
		</Grid>
	);
};
