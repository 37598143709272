import _ from 'lodash';
import {
	PlayerData,
	PlayerUpgradeType,
} from '../../../data/struct';
import {
	ResourceHelper,
	UpgradeHelper,
} from '../helper';
import { DataActionType } from './DataActionType';

export interface DataAction_Upgrade {
	actionType: DataActionType.Upgrade,
	upgradeType: PlayerUpgradeType,
	consumption: number,
}
export namespace DataAction_Upgrade {
	export function Do(data: PlayerData, action: DataAction_Upgrade): PlayerData {
		const clone_resource = _.cloneDeep(data.resource);
		const resourceHelper = new ResourceHelper(clone_resource, data.upgrade);
		const isValid = resourceHelper.decInt(action.consumption);

		if (!isValid) { return data; }

		const clone_upgrade = _.cloneDeep(data.upgrade);
		const upgradeHelper = new UpgradeHelper(clone_upgrade);
		upgradeHelper.upgrade(action.upgradeType);

		return {
			...data,
			resource: resourceHelper.get(),
			upgrade: upgradeHelper.get(),
		};
	}
}
