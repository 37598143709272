import React, { useEffect, useReducer } from 'react';
import { PlayerData } from '../data/struct';
import { DefaultData } from '../data/static';
import { DataAction, DataActionType, DataReducer, TempUtil } from '../logic';
import { DataContext } from './context';

interface Props {
	children: JSX.Element,
}

const saveStore = (store: PlayerData) => {
	localStorage.setItem('spec-idle-store', JSON.stringify(store));
};

const OnUpdate = (data: { store: PlayerData, dispatcher: React.Dispatch<DataAction> }, dt: number) => {
	const prevTime = data.store.info.playTime;
	const currTime = data.store.info.playTime + dt;

	data.dispatcher({
		actionType: DataActionType.PlayTimeUpdate,
		time: currTime,
	});
	data.dispatcher({
		actionType: DataActionType.IntellectGenerate,
		amount: (TempUtil.getCount(currTime, 1000) - TempUtil.getCount(prevTime, 1000)),
	});
};

export const DataContextProvider: React.FC<Props> = ({ children }: Props) => {
	const [store, dispatcher] = useReducer(DataReducer, DefaultData.player, undefined);

	useEffect(() => {
		saveStore(store);
	}, [store]);

	// set up game loop
	const [time, setTime] = React.useState(Date.now());
	React.useEffect(() => {
		const timer = window.setInterval(() => {
			setTime(Date.now());
		}, 1000);
		return () => {
			window.clearInterval(timer);
		};
	}, []);

	useEffect(() => {
		const delta = (1000 - (Date.now() - time));
		OnUpdate({ store, dispatcher }, delta);
	}, [time]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<DataContext.Provider value={{ store, dispatcher }}>
			{children}
		</DataContext.Provider>
	);
};
