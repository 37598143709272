import React, { useContext } from 'react';
import {
	Card,
	Grid,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import { LocaleData, MasterData } from '../../data/static';
import { DataContext } from '../../system';
import { IntDisplay, MoneyDisplay, StatDisplay } from './atom';
import { PlayerUpgradeType } from '../../data/struct';

const sx_resource: SxProps<Theme> = {
	padding: '12px',
	border: '3px solid skyblue',
	borderRadius: '10px',
	margin: '4px',
	width: 362,
	minHeight: 200,
	textAlign: 'left',
};

export const MainSideBarStatusCard: React.FC = () => {
	const { store } = useContext(DataContext);
	const { resource, stat, upgrade } = store;

	const int = resource.intellect;
	const maxInt = MasterData.intMaxTable
		.find(x => x.upgrade === upgrade
			.find(x => x.key === PlayerUpgradeType.INT_MAX)!.value
		)!.max;

	const statSum = stat.map(x => x.level).reduce((a, b) => a + b);
	const title = MasterData.titleTable
		.filter(x => x.minLevelSum <= statSum)
		.reduce((a, b) => a.minLevelSum > b.minLevelSum ? a : b)
		.title;


	return (
		<Card sx={sx_resource}>
			<Typography variant='h4'>
				{title}
			</Typography>
			<br />
			<IntDisplay
				int={int}
				maxInt={maxInt}
				name={LocaleData.player.resource.in.intellect.locale}
			/>
			<MoneyDisplay
				name={'돈'}
				money={resource.money}
			/>

			<Grid>
			</Grid>
			<br />
			{stat.map(s => {
				const locale = LocaleData.player.stat.in.find(x => x.key === s.key)?.locale ?? 'TBD';
				const maxExp = MasterData.expDataGenerator(s.level);
				return (
					<StatDisplay key={`side_stat_${s.key}`}
						name={locale}
						level={s.level}
						exp={s.exp}
						maxExp={maxExp}
					/>
				);
			})}
		</Card>
	);
};
