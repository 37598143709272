import { PlayerData } from '../../../data/struct';
import { DataActionType } from './DataActionType';

export interface DataAction_Certificate {
	actionType: DataActionType.Certificate;
	id: number;
}
export namespace DataAction_Certificate {
	export function Do(data: PlayerData, action: DataAction_Certificate): PlayerData {
		// 전처리
		const copy = [...data.certificate, action.id];

		return { ...data, certificate: copy };
	}
}
