export enum DataActionType {
	// 주기적으로 동작
	PlayTimeUpdate,
	IntellectGenerate,

	// 업그레이드
	Upgrade,
	Unlock_SelfDev,
	Unlock_Economic,

	// 활동
	SelfDevelopment,
	Economic,
	Certificate,
	Resume,
}

