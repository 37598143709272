import { Grid, Paper } from '@mui/material';
import { LocaleData, MasterData } from '../../data/static';
import { PlayerStatData } from '../../data/struct';
import { StatDisplay } from './atom';
import { SelfDevelopActivityCard } from './SelfDevelopActivityCard';

interface Props {
	s: PlayerStatData;
	children?: React.ReactNode;
}

export const SelfDevelopActivityList: React.FC<Props> = (props) => {
	const { s } = props;

	const activityList = MasterData.selfDevelopActivityList.filter(x => x.type === s.key);

	return (
		<Paper
			sx={{
				padding: '0px',
				margin: '6px',
				width: '260px',
			}}
			elevation={4}
		>
			<Grid
				container
				direction='column'
				justifyContent='flex-start'
				alignItems='center'
			>
				<StatDisplay
					name={LocaleData.player.stat.in.find(x => x.key === s.key)?.locale ?? 'TBD'}
					level={s.level}
					exp={s.exp}
					maxExp={MasterData.expDataGenerator(s.level)}
				/>
				{activityList.map((activity, i) => {
					return (
						<SelfDevelopActivityCard key={i} activity={activity} />
					);
				})}
			</Grid>
		</Paper>
	);
};
