export namespace SelfDevelopmentActivityTable {
	type StatType = (
		| 'SPEECH'
		| 'COMPETENCE'
		| 'PHYSICAL'
		| 'CHARM'
	);

	type UnlockType = (
		| 'INT_MAX_UPGRADE'
		| 'SPEECH_LEVEL'
		| 'COMPETENCE_LEVEL'
		| 'PHYSICAL_LEVEL'
		| 'CHARM_LEVEL'
	);

	type RewardType = (
		| 'SPEECH'
		| 'COMPETENCE'
		| 'PHYSICAL'
		| 'CHARM'
	);

	interface Schema {
		type: StatType;
		step: number;
		name: string;
		unlockCondition1: UnlockType | null;
		unlockConditionValue1: number;
		unlockCondition2: UnlockType | null;
		unlockConditionValue2: number;
		unlockCondition3: UnlockType | null;
		unlockConditionValue3: number;
		unlockCostCash: number;
		costTime: number;
		costInt: number;
		costCash: number;
		rewardType: RewardType; // 일단 안씀
		rewardValue: number;
		extraRewardType: RewardType | null;
		extraRewardValue: number;
		extraRewardRate: number;
	}

	export const table: Schema[] = [
		{
			type: 'COMPETENCE', step: 1, name: '책 읽기 (3s)',
			unlockCondition1: null,
			unlockConditionValue1: 0,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 0,
			costTime: 3,
			costInt: 0,
			costCash: 0,
			rewardType: 'COMPETENCE',
			rewardValue: 12,
			extraRewardType: null,
			extraRewardValue: 0,
			extraRewardRate: 0
		},
		{
			type: 'SPEECH', step: 1, name: '스피치 유튜브 보기 (3s)',
			unlockCondition1: null,
			unlockConditionValue1: 0,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 0,
			costTime: 3, costInt: 0,
			costCash: 0,
			rewardType: 'SPEECH',
			rewardValue: 12,
			extraRewardType: null,
			extraRewardValue: 0,
			extraRewardRate: 0
		},
		{
			type: 'PHYSICAL', step: 1, name: '달리기 (3s)',
			unlockCondition1: null,
			unlockConditionValue1: 0,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 0,
			costTime: 3, costInt: 0,
			costCash: 0,
			rewardType: 'PHYSICAL',
			rewardValue: 12,
			extraRewardType: null,
			extraRewardValue: 0,
			extraRewardRate: 0
		},
		{
			type: 'CHARM', step: 1, name: '다이어트 (3s)',
			unlockCondition1: null,
			unlockConditionValue1: 0,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 0,
			costTime: 3, costInt: 0,
			costCash: 0,
			rewardType: 'CHARM',
			rewardValue: 12,
			extraRewardType: null,
			extraRewardValue: 0,
			extraRewardRate: 0
		},
		{
			type: 'COMPETENCE', step: 2, name: '야걔두 인강 듣기 (5s)',
			unlockCondition1: 'COMPETENCE_LEVEL',
			unlockConditionValue1: 4,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 50000,
			costTime: 5, costInt: 5,
			costCash: 4500,
			rewardType: 'COMPETENCE',
			rewardValue: 40,
			extraRewardType: 'PHYSICAL',
			extraRewardValue: 2,
			extraRewardRate: 0.15,
		},
		{
			type: 'SPEECH', step: 2, name: '스피치 학원가기 (5s)',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 4,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 50000,
			costTime: 5, costInt: 5,
			costCash: 4500,
			rewardType: 'SPEECH',
			rewardValue: 40,
			extraRewardType: 'CHARM',
			extraRewardValue: 2,
			extraRewardRate: 0.15,
		},
		{
			type: 'PHYSICAL', step: 2, name: '헬스장 가기 (5s)',
			unlockCondition1: 'PHYSICAL_LEVEL',
			unlockConditionValue1: 4,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 50000,
			costTime: 5, costInt: 5,
			costCash: 4500,
			rewardType: 'PHYSICAL',
			rewardValue: 40,
			extraRewardType: 'SPEECH',
			extraRewardValue: 2,
			extraRewardRate: 0.15,
		},
		{
			type: 'CHARM', step: 2, name: '피부과 (5s)',
			unlockCondition1: 'CHARM_LEVEL',
			unlockConditionValue1: 4,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 50000,
			costTime: 5, costInt: 5,
			costCash: 4500,
			rewardType: 'CHARM',
			rewardValue: 40,
			extraRewardType: 'COMPETENCE',
			extraRewardValue: 2,
			extraRewardRate: 0.15,
		},
		{
			type: 'COMPETENCE', step: 3, name: '외국어 회화 (7s)',
			unlockCondition1: 'COMPETENCE_LEVEL',
			unlockConditionValue1: 12,
			unlockCondition2: 'PHYSICAL_LEVEL',
			unlockConditionValue2: 10,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 300000,
			costTime: 7, costInt: 8,
			costCash: 10000,
			rewardType: 'COMPETENCE',
			rewardValue: 112,
			extraRewardType: 'PHYSICAL',
			extraRewardValue: 3,
			extraRewardRate: 0.3,
		},
		{
			type: 'SPEECH', step: 3, name: '프리젠테이션 (7s)',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 12,
			unlockCondition2: 'CHARM_LEVEL',
			unlockConditionValue2: 10,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 300000,
			costTime: 7, costInt: 8,
			costCash: 10000,
			rewardType: 'SPEECH',
			rewardValue: 112,
			extraRewardType: 'CHARM',
			extraRewardValue: 3,
			extraRewardRate: 0.3,
		},
		{
			type: 'PHYSICAL', step: 3, name: '퍼스널 트레이닝 (7s)',
			unlockCondition1: 'PHYSICAL_LEVEL',
			unlockConditionValue1: 12,
			unlockCondition2: 'SPEECH_LEVEL',
			unlockConditionValue2: 10,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 300000,
			costTime: 7, costInt: 8,
			costCash: 10000,
			rewardType: 'PHYSICAL',
			rewardValue: 112,
			extraRewardType: 'SPEECH',
			extraRewardValue: 3,
			extraRewardRate: 0.3,
		},
		{
			type: 'CHARM', step: 3, name: '성형외과 (7s)',
			unlockCondition1: 'CHARM_LEVEL',
			unlockConditionValue1: 12,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 10,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 300000,
			costTime: 7, costInt: 8,
			costCash: 10000,
			rewardType: 'CHARM',
			rewardValue: 112,
			extraRewardType: 'COMPETENCE',
			extraRewardValue: 3,
			extraRewardRate: 0.3,
		},
	];
}
