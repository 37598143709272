export namespace UpgradeIntMaxTable {
	interface Schema {
		grade: number;
		max: number;
	}

	export const table: Schema[] = [
		{ grade: 1, max: 100 },
		{ grade: 2, max: 300 },
		{ grade: 3, max: 500 },
		{ grade: 4, max: 1000 },
		{ grade: 5, max: 1500 },
		{ grade: 6, max: 2000 },
		{ grade: 7, max: 2500 },
		{ grade: 8, max: 3000 },

	];
}
