import React, { useContext } from 'react';
import { DataContext } from '../system';
import { ResumeModal } from './popup';
import { StatSnackbar } from './popup/StatSnackbar';

export const PopupCore: React.FC<{}> = props => {
	const { store } = useContext(DataContext);

	return (
		<>
			<ResumeModal resume={store.resume} />
			{store.stat.map(x => {
				return (<StatSnackbar key={x.key} statType={x.key} level={x.level} />);
			})}
		</>
	);
};
