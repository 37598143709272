import _ from 'lodash';
import { PlayerData } from '../../../data/struct';
import { ResourceHelper } from '../helper';
import { DataActionType } from './DataActionType';

export interface DataAction_Unlock_Economic {
	actionType: DataActionType.Unlock_Economic,
	step: number,
	consumption: number,
}
export namespace DataAction_Unlock_Economic {
	export function Do(data: PlayerData, action: DataAction_Unlock_Economic): PlayerData {
		const clone_resource = _.cloneDeep(data.resource);
		const resourceHelper = new ResourceHelper(clone_resource, data.upgrade);
		const isValid = resourceHelper.decMoney(action.consumption);

		if (!isValid) { return data; }

		return {
			...data,
			resource: resourceHelper.get(),
			economic: [...data.economic, action.step],
		};
	}
}
