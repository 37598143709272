export namespace UpgradeCostTable {
	type UpgradeType = (
		| 'INT_GEN'
		| 'INT_MAX'
		| 'SPEECH'
		| 'COMPETENCE'
		| 'PHYSICAL'
		| 'CHARM'
	);

	interface Schema {
		type: UpgradeType;
		grade: number;
		costInt: number;
	}

	export const table: Schema[] = [
		{ type: 'INT_GEN', grade: 1, costInt: 100 },
		{ type: 'INT_GEN', grade: 2, costInt: 300 },
		{ type: 'INT_GEN', grade: 3, costInt: 500 },
		{ type: 'INT_GEN', grade: 4, costInt: 1000 },
		{ type: 'INT_GEN', grade: 5, costInt: 1500 },
		{ type: 'INT_GEN', grade: 6, costInt: 2000 },

		{ type: 'INT_MAX', grade: 1, costInt: 100 },
		{ type: 'INT_MAX', grade: 2, costInt: 300 },
		{ type: 'INT_MAX', grade: 3, costInt: 500 },
		{ type: 'INT_MAX', grade: 4, costInt: 1000 },
		{ type: 'INT_MAX', grade: 5, costInt: 1500 },
		{ type: 'INT_MAX', grade: 6, costInt: 2000 },
		{ type: 'INT_MAX', grade: 7, costInt: 2500 },

		{ type: 'SPEECH', grade: 1, costInt: 100 },
		{ type: 'SPEECH', grade: 2, costInt: 300 },
		{ type: 'SPEECH', grade: 3, costInt: 500 },
		{ type: 'SPEECH', grade: 4, costInt: 1000 },
		{ type: 'SPEECH', grade: 5, costInt: 1500 },
		{ type: 'SPEECH', grade: 6, costInt: 2000 },
		{ type: 'SPEECH', grade: 7, costInt: 2500 },

		{ type: 'COMPETENCE', grade: 1, costInt: 100 },
		{ type: 'COMPETENCE', grade: 2, costInt: 300 },
		{ type: 'COMPETENCE', grade: 3, costInt: 500 },
		{ type: 'COMPETENCE', grade: 4, costInt: 1000 },
		{ type: 'COMPETENCE', grade: 5, costInt: 1500 },
		{ type: 'COMPETENCE', grade: 6, costInt: 2000 },
		{ type: 'COMPETENCE', grade: 7, costInt: 2500 },

		{ type: 'PHYSICAL', grade: 1, costInt: 100 },
		{ type: 'PHYSICAL', grade: 2, costInt: 300 },
		{ type: 'PHYSICAL', grade: 3, costInt: 500 },
		{ type: 'PHYSICAL', grade: 4, costInt: 1000 },
		{ type: 'PHYSICAL', grade: 5, costInt: 1500 },
		{ type: 'PHYSICAL', grade: 6, costInt: 2000 },
		{ type: 'PHYSICAL', grade: 7, costInt: 2500 },

	
		{ type: 'CHARM', grade: 1, costInt: 100 },
		{ type: 'CHARM', grade: 2, costInt: 300 },
		{ type: 'CHARM', grade: 3, costInt: 500 },
		{ type: 'CHARM', grade: 4, costInt: 1000 },
		{ type: 'CHARM', grade: 5, costInt: 1500 },
		{ type: 'CHARM', grade: 6, costInt: 2000 },
		{ type: 'CHARM', grade: 7, costInt: 2500 },



		
	];
}
