export namespace UpgradeStatBonusTable {
	interface Schema {
		grade: number;
		bonus: number;
	}

	export const table: Schema[] = [
		{ grade: 1, bonus: 0 },
		{ grade: 2, bonus: 3 },
		{ grade: 3, bonus: 4 },
		{ grade: 4, bonus: 5 },
		{ grade: 5, bonus: 6 },
		{ grade: 6, bonus: 7 },
		{ grade: 6, bonus: 10 },
	];
}
