import _ from 'lodash';
import { useContext } from 'react';
import { Paper, Typography } from '@mui/material';
import { LocaleData, MasterData } from '../../data/static';
import {
	ActivityUnlockCondition,
	CertificateActivityData,
	PlayerData,
	PlayerStatType,
	PlayerUpgradeType,
} from '../../data/struct';
import { DataAction, DataActionType } from '../../logic';
import { DataContext } from '../../system';
import { ActivityList, ActivityProp } from './atom';

export const CertificateActivityPanel: React.FC = () => {
	const { store, dispatcher } = useContext(DataContext);

	const propList = propActivityList({ data: store, dispatcher });

	return (<ActivityList shape='free' propList={propList} />);
};

function propActivityList(
	context: {
		data: PlayerData,
		dispatcher: (value: DataAction) => void,
	},
): ActivityProp[] {
	return MasterData.certificateData
		.filter(x => !context.data.certificate.includes(x.id))
		.map(act => {
			return propActivity(act, context);
		});
}

function propActivity(
	act: CertificateActivityData,
	context: {
		data: PlayerData,
		dispatcher: (value: DataAction) => void,
	},
): ActivityProp {
	const isActive = activityUnlockCheck(act.unlockConditions, context.data);

	return {
		buttonName: act.name,
		displayChildren: isActive
			? (<Paper><Typography>{`${act.summary}`}</Typography></Paper>)
			: (
				<>
					{act.unlockConditions.map((unlockCondition, i) => {
						const found = LocaleData.activityUnlockCondition.find(x => x.condition === unlockCondition.condition);
						return (
							<Typography key={i} color='red'>
								{`${found?.locale ?? 'TBD'} ${unlockCondition.value} 필요`}
							</Typography>
						);
					})}
				</>
			),

		isActive,
		isWork: false,

		startTime: 0,
		workTime: 0,

		onClick: () => {
			context.dispatcher({
				actionType: DataActionType.Certificate,
				id: act.id,
			});
		},
	};
}

function activityUnlockCheck(unlockConditions: ActivityUnlockCondition[], data: PlayerData): boolean {
	const conditionResults = unlockConditions.map(condition => {
		switch (condition.condition) {
			case ActivityUnlockCondition.Type.SPEECH_LEVEL:
				const speechLevel = data.stat.find(x => x.key === PlayerStatType.SPEECH)?.level ?? 0;
				return condition.value <= speechLevel;
			case ActivityUnlockCondition.Type.COMPETENCE_LEVEL:
				const competenceLevel = data.stat.find(x => x.key === PlayerStatType.COMPETENCE)?.level ?? 0;
				return condition.value <= competenceLevel;
			case ActivityUnlockCondition.Type.PHYSICAL_LEVEL:
				const physicalLevel = data.stat.find(x => x.key === PlayerStatType.PHYSICAL)?.level ?? 0;
				return condition.value <= physicalLevel;
			case ActivityUnlockCondition.Type.CHARM_LEVEL:
				const charmLevel = data.stat.find(x => x.key === PlayerStatType.CHARM)?.level ?? 0;
				return condition.value <= charmLevel;
			case ActivityUnlockCondition.Type.INT_MAX_UPGRADE:
				const intMaxUpgrade = data.upgrade.find(x => x.key === PlayerUpgradeType.INT_MAX)?.value ?? 1;
				return condition.value <= intMaxUpgrade;
		}
		return undefined;
	}).filter(x => x !== undefined);
	return _.every(conditionResults);
}
