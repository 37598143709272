import _ from 'lodash';
import { useContext } from 'react';
import {
	Box,
	Button,
	Card,
	Grid,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import { LocaleData } from '../../../data/static';
import {
	ActivityUnlockCondition,
	PlayerData,
	PlayerStatType,
	PlayerUpgradeType,
} from '../../../data/struct';
import { DataContext } from '../../../system';

const sx_card: SxProps<Theme> = {
	border: '4px solid cornflowerblue',
	margin: '6px',
	width: '230px',
};

const sx_card_content: SxProps<Theme> = {
	padding: '8px',
	width: '214px',
};

const sx_card_button: SxProps<Theme> = {
	padding: '12px',
	border: '3px solid skyblue',
	borderRadius: 3,
	margin: '8px',
	width: '200px',
	height: '60px',
};

const sx_condition_complete: SxProps<Theme> = {
	textDecoration: 'line-through',
	color: 'gray',
};

const sx_condition_incomplete: SxProps<Theme> = {
	color: 'red',
};

interface Props {
	unlockConditions: ActivityUnlockCondition[];
	unlockCostCash: number;
	onUnlock: () => void;
}

export const ActivityLockedCard: React.FC<Props> = (props) => {
	const { store } = useContext(DataContext);
	const { unlockConditions, unlockCostCash, onUnlock } = props;

	const conditionList = unlockConditions.map(condition => {
		const isComplete = unlockCheck(condition, store);
		return { condition, isComplete };
	});

	const allClear = _.every(conditionList, x => x.isComplete);

	return (
		<Card sx={sx_card}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
			>
				<Typography>
					잠김
				</Typography>
				{allClear
					? <Button sx={sx_card_button} onClick={onUnlock}>{`해금하기 (${unlockCostCash} 원)`}</Button>
					: <Box sx={sx_card_content}>
						{conditionList.map(({ condition, isComplete }, i) => {
							const locale = LocaleData.activityUnlockCondition.find(x => x.condition === condition.condition);
							return (
								<Typography key={i} sx={isComplete ? sx_condition_complete : sx_condition_incomplete} >
									{`${locale?.locale ?? 'TBD'} ${condition.value} 필요`}
								</Typography>
							);
						})}
					</Box>
				}
			</Grid>
		</Card >
	);
};

function unlockCheck(condition: ActivityUnlockCondition, data: PlayerData): boolean {
	switch (condition.condition) {
		case ActivityUnlockCondition.Type.SPEECH_LEVEL:
			const speechLevel = data.stat.find(x => x.key === PlayerStatType.SPEECH)?.level ?? 0;
			return condition.value <= speechLevel;
		case ActivityUnlockCondition.Type.COMPETENCE_LEVEL:
			const competenceLevel = data.stat.find(x => x.key === PlayerStatType.COMPETENCE)?.level ?? 0;
			return condition.value <= competenceLevel;
		case ActivityUnlockCondition.Type.PHYSICAL_LEVEL:
			const physicalLevel = data.stat.find(x => x.key === PlayerStatType.PHYSICAL)?.level ?? 0;
			return condition.value <= physicalLevel;
		case ActivityUnlockCondition.Type.CHARM_LEVEL:
			const charmLevel = data.stat.find(x => x.key === PlayerStatType.CHARM)?.level ?? 0;
			return condition.value <= charmLevel;
		case ActivityUnlockCondition.Type.INT_MAX_UPGRADE:
			const intMaxUpgrade = data.upgrade.find(x => x.key === PlayerUpgradeType.INT_MAX)?.value ?? 1;
			return condition.value <= intMaxUpgrade;
	}
}
