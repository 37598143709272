import { MasterData } from '../../../data/static';
import { PlayerResourceData, PlayerUpgradeData, PlayerUpgradeType } from '../../../data/struct';

export class ResourceHelper {
	private readonly int_max_upgrade: number;
	constructor(
		private readonly clone_resource: PlayerResourceData,
		upgrades: PlayerUpgradeData[]
	) {
		this.int_max_upgrade = upgrades.find(x => x.key === PlayerUpgradeType.INT_MAX)?.value ?? 1;
	}

	public incInt(increment: number): void {
		const max = MasterData.intMaxTable.find(x => x.upgrade === this.int_max_upgrade)?.max ?? 1000;

		this.clone_resource.intellect = Math.min(this.clone_resource.intellect + increment, max);
	}

	public decInt(decrement: number): boolean {
		if (this.clone_resource.intellect < decrement) { return false; }
		this.clone_resource.intellect -= decrement;
		return true;
	}

	public incMoney(increment: number): void {
		this.clone_resource.money += increment;
	}

	public decMoney(decrement: number): boolean {
		if (this.clone_resource.money < decrement) { return false; }
		this.clone_resource.money -= decrement;
		return true;
	}

	public get() {
		return this.clone_resource;
	}
}
