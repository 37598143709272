export enum PlayerUpgradeType {
	INT_GEN = 'INT_GEN',
	INT_MAX = 'INT_MAX',

	SPEECH = 'SPEECH',
	COMPETENCE = 'COMPETENCE',
	PHYSICAL = 'PHYSICAL',
	CHARM = 'CHARM',
}

export interface PlayerUpgradeData {
	key: PlayerUpgradeType;
	value: number;
}
