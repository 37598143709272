export namespace CertificateActivityTable {
	type UnlockType = (
		| 'INT_MAX_UPGRADE'
		| 'SPEECH_LEVEL'
		| 'COMPETENCE_LEVEL'
		| 'PHYSICAL_LEVEL'
		| 'CHARM_LEVEL'
	);

	interface Schema {
		id: number;
		name: string;
		summary: string;
		unlockCondition1: UnlockType | null;
		unlockConditionValue1: number;
		unlockCondition2: UnlockType | null;
		unlockConditionValue2: number;
		unlockCondition3: UnlockType | null;
		unlockConditionValue3: number;
	}

	export const table: Schema[] = [
		{
			id: 1, name: '사람구실',
			summary: '어디가서 사람 구실은 할 준비가 되었습니다.',
			unlockCondition1: 'COMPETENCE_LEVEL',
			unlockConditionValue1: 2,
			unlockCondition2: 'SPEECH_LEVEL',
			unlockConditionValue2: 2,
			unlockCondition3: null,
			unlockConditionValue3: 0,
		},

		{
			id: 2, name: '레크레이션 강사',
			summary: '사람들을 즐겁게 하는 말빨을 가졌습니다.',
			unlockCondition1: 'COMPETENCE_LEVEL',
			unlockConditionValue1: 7,
			unlockCondition2: 'SPEECH_LEVEL',
			unlockConditionValue2: 10,
			unlockCondition3: 'CHARM_LEVEL',
			unlockConditionValue3: 7,
		},
		{
			id: 3, name: '생활 스포츠 지도사',
			summary: '몸도 쓸 줄 알고 가르칠 준비가 되었군요.',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 10,
			unlockCondition2: 'PHYSICAL_LEVEL',
			unlockConditionValue2: 15,
			unlockCondition3: 'CHARM_LEVEL',
			unlockConditionValue3: 7,
		},
		{
			id: 4, name: '외모 증명',
			summary: '몸도 좋고 외모도 출중하네요.',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 10,
			unlockCondition2: 'PHYSICAL_LEVEL',
			unlockConditionValue2: 17,
			unlockCondition3: 'CHARM_LEVEL',
			unlockConditionValue3: 20,
		},

		{
			id: 5, name: '인기 과외 선생님',
			summary: '대체 못하는게 뭐죠?.',
			unlockCondition1: 'COMPETENCE_LEVEL',
			unlockConditionValue1: 30,
			unlockCondition2: 'SPEECH_LEVEL',
			unlockConditionValue2: 20,
			unlockCondition3: 'CHARM_LEVEL',
			unlockConditionValue3: 22,
		},








	];
}
