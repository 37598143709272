import React from 'react';
import { DefaultData } from '../../data/static';
import { FlowData } from '../../data/struct';
import { FlowAction } from '../../logic';

interface FlowContextInner {
	flowContext: FlowData,
	flowDispatcher: React.Dispatch<FlowAction>,
}

export const FlowContext = React.createContext<FlowContextInner>({
	flowContext: { ...DefaultData.flow },
	flowDispatcher: () => { },
});
