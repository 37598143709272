import {
	SelfDevelopActivityData,
	PlayerData,
	PlayerStatType,
	PlayerUpgradeType,
	ActivityUnlockCondition,
} from '../struct';

// 아무도 날 막지 못하셈
interface LocaleFieldType {
	locale: string,
	summary?: string,
}
interface LocaleObjectType<T> {
	locale: string,
	in: Locale<T>
}
interface LocaleArrayType<T> {
	locale: string,
	in: LocaleArray<Pick<T, PK<T>>>
}

type LocaleArrayElem<PK> = PK & LocaleFieldType;
type LocaleArray<PK> = Array<LocaleArrayElem<PK>>;

type PK<E> = { [PK in keyof E]: PK extends `key${string}` ? PK : never; }[keyof E];

type Locale<T> = {
	[K in keyof T]: T[K] extends Array<unknown>
	? T[K] extends (infer E)[] ? LocaleArrayType<E> : never
	: T[K] extends object ? LocaleObjectType<T[K]> : LocaleFieldType;
}

export namespace LocaleData {
	export const player: Locale<PlayerData> = {
		info: {
			locale: 'TBD',
			in: {
				playTime: { locale: '플레이 타임' },
			},
		},
		resource: {
			locale: 'TBD',
			in: {
				intellect: { locale: '지능' },
				money: { locale: '돈' },
			}
		},
		stat: {
			locale: '스탯',
			in: [
				{ key: PlayerStatType.SPEECH, locale: '화술' },
				{ key: PlayerStatType.COMPETENCE, locale: '언어능력' },
				{ key: PlayerStatType.PHYSICAL, locale: '운동능력' },
				{ key: PlayerStatType.CHARM, locale: '외모' },
			]
		},
		upgrade: {
			locale: '성장 해금 정보',
			in: [
				{ key: PlayerUpgradeType.INT_GEN, locale: '강황 섭취', summary: '초당 지능 증가량 상승' },
				{ key: PlayerUpgradeType.INT_MAX, locale: '득도', summary: '지능 최대치 상승' },

				{ key: PlayerUpgradeType.SPEECH, locale: '화술 재능', summary: '화술 exp 획득량 보너스 증가' },
				{ key: PlayerUpgradeType.COMPETENCE, locale: '언어능력 재능', summary: '언어능력 exp 획득량 보너스 증가' },
				{ key: PlayerUpgradeType.PHYSICAL, locale: '운동능력 재능', summary: '운동능력 exp 획득량 보너스 증가' },
				{ key: PlayerUpgradeType.CHARM, locale: '외모 재능', summary: '외모 exp 획득량 보너스 증가' },
			],
		},
		selfDevelopment: { locale: '', in: [] },
		economic: { locale: '', in: [] },
		certificate: { locale: '보유 자격증', in: [] },
		resume: { locale: '회사' },
	};

	export const selfDevelopActivity: Locale<SelfDevelopActivityData> = {
		type: { locale: 'TBD' },
		step: { locale: '등급' },
		name: { locale: '이름' },
		unlockConditions: {
			locale: '언락조건',
			in: []
		},
		unlockCost: {
			locale: '언락비용',
			in: {
				cash: { locale: '돈' },
				int: { locale: '지능' },
				time: { locale: '시간' },
			}
		},
		cost: {
			locale: '비용',
			in: {
				cash: { locale: '돈' },
				int: { locale: '지능' },
				time: { locale: '시간' },
			}
		},
		reward: {
			locale: '보상',
			in: {
				amount: { locale: '수량' },
			}
		},
		extraRewards: {
			locale: '추가 보상',
			in: [],
		},
	};

	type PK_ActivityUnlockCondition = Pick<ActivityUnlockCondition, 'condition'>;
	export type ActivityUnlockConditionLocale = LocaleArrayElem<PK_ActivityUnlockCondition>;
	export type ActivityUnlockConditionLocaleList = LocaleArray<PK_ActivityUnlockCondition>;
	export const activityUnlockCondition: ActivityUnlockConditionLocaleList = [
		{ condition: ActivityUnlockCondition.Type.INT_MAX_UPGRADE, locale: '깨달음 업그레이드' },
		{ condition: ActivityUnlockCondition.Type.SPEECH_LEVEL, locale: '화술 레벨' },
		{ condition: ActivityUnlockCondition.Type.COMPETENCE_LEVEL, locale: '언어능력 레벨' },
		{ condition: ActivityUnlockCondition.Type.PHYSICAL_LEVEL, locale: '운동능력 레벨' },
		{ condition: ActivityUnlockCondition.Type.CHARM_LEVEL, locale: '외모 레벨' },
	];
}
