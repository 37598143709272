import React, { useContext } from 'react';
import {
	Card,
	List,
	ListItem,
	SxProps,
	Theme,
	Tooltip,
} from '@mui/material';
import { LocaleData, MasterData } from '../../data/static';
import { PlayerUpgradeType } from '../../data/struct';
import { DataActionType } from '../../logic';
import { DataContext } from '../../system';
import { ResourceManageButton } from './atom';

const sx: SxProps<Theme> = {
	padding: '8px',
	border: '3px solid skyblue',
	borderRadius: '10px',
	margin: '4px',
	width: 370,
	minHeight: 200,
	textAlign: 'left',
};

export const MainSideBarUpgradeCard: React.FC = () => {
	const { store, dispatcher } = useContext(DataContext);
	const { upgrade } = store;

	return (
		<Card sx={sx}>
			<List>
				{Object.values(PlayerUpgradeType).map((type, i) => {
					const upgradeInfo = upgrade.find(x => x.key === type)?.value ?? 1;

					const staticData = MasterData.upgradeTable.find(x => x.type === type && x.grade === upgradeInfo);
					const consumption = staticData ? staticData.consumption : 0;

					const localeData = LocaleData.player.upgrade.in.find(x => x.key === type);
					const locale = localeData?.locale ?? type.toString();
					const summary = localeData?.summary ?? 'TBD';

					const onExecute = () => {
						dispatcher({
							actionType: DataActionType.Upgrade,
							upgradeType: type,
							consumption,
						});
					};

					const divider = Object.values(PlayerUpgradeType).length !== i + 1;

					const locale_int = LocaleData.player.resource.in.intellect.locale;

					return (
						<Tooltip title={summary} arrow key={type}>
							<ListItem divider={divider} >
								<ResourceManageButton
									cost={`${(consumption / 100).toFixed(0)} ${locale_int}`}
									name={locale}
									step={upgradeInfo}
									onClick={onExecute}
								/>
							</ListItem>
						</Tooltip>
					);
				})}
			</List>
		</Card >
	);
};
