import { isNonNullable } from '../../common';
import MasterDataRow from '../../masterdata';
import {
	ActivityExtraReward,
	ActivityGoal,
	ActivityUnlockCondition,
	CertificateActivityData,
	EconomicActivityData,
	IntGenData,
	IntMaxData,
	PlayerStatType,
	PlayerUpgradeType,
	ResumeActivityData,
	SelfDevelopActivityData,
	StatBonusData,
	UpgradeData,
} from '../struct';

export namespace MasterData {
	export const selfDevelopActivityList: SelfDevelopActivityData[]
		= MasterDataRow.selfDevelopmentActivityTable.map((row): SelfDevelopActivityData => ({
			type: PlayerStatType[row.type],
			step: row.step,
			name: row.name,

			unlockConditions: [
				row.unlockCondition1 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition1], value: row.unlockConditionValue1 } : null,
				row.unlockCondition2 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition2], value: row.unlockConditionValue2 } : null,
				row.unlockCondition3 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition3], value: row.unlockConditionValue3 } : null,
			].filter(isNonNullable),
			unlockCost: { cash: row.unlockCostCash, int: 0, time: 0 },

			cost: { cash: row.costCash, int: row.costInt * 100, time: row.costTime },
			reward: { amount: row.rewardValue },
			extraRewards: [
				row.extraRewardType ? { type: ActivityExtraReward.Type[row.extraRewardType], amount: row.extraRewardValue, rate: row.extraRewardRate } : null,
			].filter(isNonNullable),
		}));

	export const economicActivityData: EconomicActivityData[]
		= MasterDataRow.economicActivityTable.map((row): EconomicActivityData => ({
			upgrade: row.step,
			name: row.name,

			unlockConditions: [
				row.unlockCondition1 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition1], value: row.unlockConditionValue1 } : null,
				row.unlockCondition2 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition2], value: row.unlockConditionValue2 } : null,
				row.unlockCondition3 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition3], value: row.unlockConditionValue3 } : null,
			].filter(isNonNullable),
			unlockCost: { cash: row.unlockCostCash, int: 0, time: 0 },

			cost: { cash: 0, int: 0, time: row.costTime },
			reward: { amount: row.rewardValue },
		}));

	export const certificateData: CertificateActivityData[]
		= MasterDataRow.certificateActivityTable.map((row): CertificateActivityData => ({
			id: row.id,
			name: row.name,
			summary: row.summary,
			unlockConditions: [
				row.unlockCondition1 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition1], value: row.unlockConditionValue1 } : null,
				row.unlockCondition2 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition2], value: row.unlockConditionValue2 } : null,
				row.unlockCondition3 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition3], value: row.unlockConditionValue3 } : null,
			].filter(isNonNullable),
		}));

	export const resumeData: ResumeActivityData[]
		= MasterDataRow.resumeActivityTable.map((row): ResumeActivityData => ({
			step: row.step,
			name: row.name,
			unlockConditions: [
				row.unlockCondition1 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition1], value: row.unlockConditionValue1 } : null,
				row.unlockCondition2 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition2], value: row.unlockConditionValue2 } : null,
				row.unlockCondition3 ? { condition: ActivityUnlockCondition.Type[row.unlockCondition3], value: row.unlockConditionValue3 } : null,
			].filter(isNonNullable),
			exposeTime: row.exposureTime * 1000,
			coolTime: MasterDataRow.resumeActivityConstants.ReExposeTime * 1000,
			goal: [
				{ type: ActivityGoal.Type.SPEECH, value: row.goalSpeech },
				{ type: ActivityGoal.Type.COMPETENCE, value: row.goalCompetence },
				{ type: ActivityGoal.Type.PHYSICAL, value: row.goalPhysical },
				{ type: ActivityGoal.Type.CHARM, value: row.goalCharm },
			],
			reward: { amount: row.rewardValue },
		}));

	export const upgradeTable: UpgradeData[]
		= MasterDataRow.upgradeCostTable.map((row): UpgradeData => ({
			type: PlayerUpgradeType[row.type],
			grade: row.grade,
			consumption: row.costInt * 100,
		}));

	export const intGenTable: IntGenData[]
		= MasterDataRow.upgradeIntGenTable.map((row): IntGenData => ({
			upgrade: row.grade,
			gen: row.gen * 100,
		}));

	export const intMaxTable: IntMaxData[]
		= MasterDataRow.upgradeIntMaxTable.map((row): IntMaxData => ({
			upgrade: row.grade,
			max: row.max * 100,
		}));

	export const statBonusTable: StatBonusData[]
		= MasterDataRow.upgradeStatBonusTable.map((row): StatBonusData => ({
			upgrade: row.grade,
			bonus: row.bonus,
		}));

	export const titleTable = MasterDataRow.titleTable;

	/**
	 * @summary maxExp 데이터 생성기
	 * @description 표로 하면 너무 커질거같아서 수식으로 대체
	 */
	export const expDataGenerator: (a: number) => number = currLevel => {
		const nextLevel = currLevel + 1;
		if (nextLevel === 1) { return 0; }
		return Math.floor((nextLevel - 1 + 300 * Math.pow(2, (nextLevel - 1) / 7)) / 4) + expDataGenerator(currLevel - 1);
	};
}
