import { Grid, Paper, SxProps, Theme } from '@mui/material';
import { ActivityCard } from './ActivityCard';
import { ActivityProp } from './ActivityProp';

const sxMap = new Map<'row' | 'column' | 'free', SxProps<Theme>>([
	['row', {
		padding: '0px',
		margin: '6px',
	}],
	['column', {
		padding: '0px',
		margin: '6px',
		width: '260px',
	}],
	['free', {
		padding: '0px',
		margin: '6px',
		maxWidth: '1000px',
	}],
]);

const shapeMap = new Map<'row' | 'column' | 'free', object>([
	['row', {
		direction: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	}],
	['column', {
		direction: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	}],
	['free', {}],
]);

interface Props {
	shape: 'row' | 'column' | 'free';
	propList: Array<ActivityProp>;
	children?: React.ReactNode;
}

export const ActivityList: React.FC<Props> = (props) => {
	const { shape, children, propList } = props;

	return (
		<Paper sx={sxMap.get(shape)} elevation={4} >
			<Grid container {...shapeMap.get(shape)}>
				{children}
				{propList.map((prop, i) => {
					return (
						<ActivityCard key={`tab_common_list_${i}`} {...prop} />
					);
				})}
			</Grid>
		</Paper>
	);
};
