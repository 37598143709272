import {
	Box,
	Grid,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';

const sx_grid: SxProps<Theme> = {
	width: '100%',
	justifyContent: 'flex,-start',
	alignItems: 'center',
};

const sx_name: SxProps<Theme> = {
	width: '15%',
};

const sx_lv: SxProps<Theme> = {
	width: '85%',
	textAlign: 'right',
};

interface Props {
	name: string;
	money: number;
}

export const MoneyDisplay: React.FC<Props> = (props) => {
	const { name, money } = props;

	return (
		<Grid container sx={sx_grid}>
			<Box sx={sx_name}>
				<Typography>{name}</Typography>
			</Box>

			<Box sx={sx_lv}>
				<Typography>{`${money} 원`}</Typography>
			</Box>
		</Grid >
	);
};
