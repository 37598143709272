import React, { useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	SxProps,
	Tab,
	Tabs,
	Theme,
} from '@mui/material';
import {
	CertificateActivityPanel,
	EconomicActivityPanel,
	SelfDevelopActivityPanel,
} from './tab';
import { ResumeActivityPanel } from './tab/ResumeActivityPanel';


const sx_panel: SxProps<Theme> = {
	margin: '4px',
	textAlign: 'left',
};

export const MainTabPanel: React.FC = () => {
	const [value, setValue] = useState(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Card sx={sx_panel}>
			<CardContent sx={{
				paddingTop: 0,
				paddingX: 1,
			}}>
				<Tabs value={value} onChange={handleChange}>
					<Tab label='자기 개발' id={`simple-tab-${0}`} />
					<Tab label='경제 활동' id={`simple-tab-${1}`} />
					<Tab label='자격증' id={`simple-tab-${2}`} />
					<Tab label='이력서' id={`simple-tab-${3}`} />
				</Tabs>

				<TabPanel index={0} value={value}>
					<SelfDevelopActivityPanel />
				</TabPanel>

				<TabPanel index={1} value={value}>
					<EconomicActivityPanel />
				</TabPanel>

				<TabPanel index={2} value={value}>
					<CertificateActivityPanel />
				</TabPanel>

				<TabPanel index={3} value={value}>
					<ResumeActivityPanel />
				</TabPanel>
			</CardContent>
		</Card>
	);
};

// 귀찮아서 복붙
// https://mui.com/material-ui/react-tabs/
interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}
