import { Box, Grid, LinearProgress, SxProps, Theme, Typography } from '@mui/material';

const sx_grid_outer: SxProps<Theme> = {
	width: '100%',
	direction: 'column',
	justifyContent: 'flex-start',
	alignItems: 'center',
};

const sx_grid_inner: SxProps<Theme> = {
	width: '100%',
	direction: 'row',
	justifyContent: 'flex-start',
	alignItems: 'center',
};

const sx_name: SxProps<Theme> = {
	width: '30%',
	textAlign: 'center',
};

const sx_lv: SxProps<Theme> = {
	width: '70%',
};

const sx_progress: SxProps<Theme> = {
	margin: '0% 5% 0% 5%',
	width: '90%',
};

const sx_percent: SxProps<Theme> = {
	margin: '0% 5% 0% 5%',
	width: '90%',
	textAlign: 'right',
};

interface Props {
	name: string;
	level: number;
	exp: number;
	maxExp: number;
}

export const StatDisplay: React.FC<Props> = (props) => {
	const { exp, level, maxExp, name } = props;

	const expStr = (Math.round(exp * 100) / 100).toFixed(2);
	const maxExpStr = (Math.round(maxExp * 100) / 100).toFixed(0);

	return (
		<Grid container sx={sx_grid_outer}>
			<Grid container sx={sx_grid_inner}>
				<Box sx={sx_name}>
					<Typography>{name}</Typography>
				</Box>

				<Box sx={sx_lv}>
					<Typography>{`LV: ${level}`}</Typography>
				</Box>
			</Grid>

			<Box sx={sx_progress}>
				<LinearProgress
					variant='determinate'
					sx={{ '& .MuiLinearProgress-bar': { transition: exp === 0 ? 'none' : 'transform 0.2s linear' } }}
					value={100 * exp / maxExp}
				/>
			</Box>
			<Box sx={sx_percent}>
				<Typography>
					{`(${expStr}/${maxExpStr}) ${(Math.round(10000 * exp / maxExp) / 100).toFixed(2)}%`}
				</Typography>
			</Box>
		</Grid>
	);
};
