import { SelfDevelopmentActivityTable } from './SelfDevelopmentActivityTable';
import { EconomicActivityTable } from './EconomicActivityTable';
import { CertificateActivityTable } from './CertificateActivityTable';
import { ResumeActivityTable } from './ResumeActivityTable';
import { UpgradeCostTable } from './UpgradeCostTable';
import { TitleTable } from './TitleTable';
import { UpgradeIntGenTable } from './UpgradeIntGenTable';
import { UpgradeIntMaxTable } from './UpgradeIntMaxTable';
import { UpgradeStatBonusTable } from './UpgradeStatBonusTable';

namespace MasterDataRow {
	// activity table
	export const selfDevelopmentActivityTable = SelfDevelopmentActivityTable.table;
	export const economicActivityTable = EconomicActivityTable.table;
	export const certificateActivityTable = CertificateActivityTable.table;
	export const resumeActivityTable = ResumeActivityTable.table;

	// upgrade
	export const upgradeCostTable = UpgradeCostTable.table;
	export const upgradeIntGenTable = UpgradeIntGenTable.table;
	export const upgradeIntMaxTable = UpgradeIntMaxTable.table;
	export const upgradeStatBonusTable = UpgradeStatBonusTable.table;

	// etc
	export const titleTable = TitleTable.table;

	// constant
	export const resumeActivityConstants = ResumeActivityTable.constants;
}

export default MasterDataRow;
