export enum PlayerStatType {
	SPEECH = 'SPEECH',
	COMPETENCE = 'COMPETENCE',
	PHYSICAL = 'PHYSICAL',
	CHARM = 'CHARM',
}

export interface PlayerStatData {
	key: PlayerStatType;
	level: number;
	exp: number;
}
