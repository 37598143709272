import React from 'react';
import { DefaultData } from '../../data/static';
import { PlayerData } from '../../data/struct';
import { DataAction } from '../../logic';

interface DataContextInner {
	store: PlayerData,
	dispatcher: React.Dispatch<DataAction>,
}

export const DataContext = React.createContext<DataContextInner>({
	store: ({ ...DefaultData.player }),
	dispatcher: () => { },
});
