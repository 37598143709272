import { PlayerData } from '../../data/struct';
import {
	DataAction,
	DataActionType,
	DataAction_Certificate,
	DataAction_Economic,
	DataAction_IntellectGenerate,
	DataAction_PlayTimeUpdate,
	DataAction_Resume,
	DataAction_SelfDevelopment,
	DataAction_Unlock_Economic,
	DataAction_Unlock_SelfDev,
	DataAction_Upgrade,
} from './action';

export const DataReducer = (data: PlayerData, action: DataAction): PlayerData => {
	// 왜인지 두번씩 불린다. idempotent 하게 돌게 만들기 위해서 무지성으로 deep clone
	// https://github.com/facebook/react/issues/16295
	// 순수함수여야 한다고 함.
	// 그래서 일단 deep clone 유지하는게 맞을듯?

	switch (action.actionType) {
		case DataActionType.PlayTimeUpdate:
			return DataAction_PlayTimeUpdate.Do(data, action);
		case DataActionType.IntellectGenerate:
			return DataAction_IntellectGenerate.Do(data, action);
		case DataActionType.Upgrade:
			return DataAction_Upgrade.Do(data, action);
		case DataActionType.Unlock_SelfDev:
			return DataAction_Unlock_SelfDev.Do(data, action);
		case DataActionType.Unlock_Economic:
			return DataAction_Unlock_Economic.Do(data, action);
		case DataActionType.SelfDevelopment:
			return DataAction_SelfDevelopment.Do(data, action);
		case DataActionType.Economic:
			return DataAction_Economic.Do(data, action);
		case DataActionType.Certificate:
			return DataAction_Certificate.Do(data, action);
		case DataActionType.Resume:
			return DataAction_Resume.Do(data, action);
		default:
			throw new Error('not implemented');
	}
};
