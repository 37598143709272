import { FlowData, WorkFlowData } from '../../../data/struct';
import { FlowActionType } from './FlowActionType';

export interface FlowAction_WorkChange {
	actionType: FlowActionType.WorkChange,
	data: WorkFlowData;
}
export namespace FlowAction_WorkChange {
	export function Do(data: FlowData, action: FlowAction_WorkChange): FlowData {
		return { ...data, workFlow: action.data };
	}
}
