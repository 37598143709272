import { useContext } from 'react';
import { Grid } from '@mui/material';
import { DataContext } from '../../system';
import { SelfDevelopActivityList } from './SelfDevelopActivityList';

export const SelfDevelopActivityPanel: React.FC = () => {
	const { store } = useContext(DataContext);
	const { stat } = store;

	return (
		<Grid
			container
			direction='row'
		>
			{stat.map(s => {
				return (<SelfDevelopActivityList key={`tab_self_${s.key}`}
					s={s}
				/>);
			})}
		</Grid>
	);
};
