export namespace UpgradeIntGenTable {
	interface Schema {
		grade: number;
		gen: number;
	}

	export const table: Schema[] = [
		{ grade: 1, gen: 0.5 },
		{ grade: 2, gen: 0.7 },
		{ grade: 3, gen: 1 },
		{ grade: 4, gen: 1.2 },
		{ grade: 5, gen: 1.4 },
		{ grade: 6, gen: 1.6},
		{ grade: 7, gen: 1.8 },
	];
}
