import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { DefaultData, LocaleData } from '../../data/static';
import { PlayerStatType } from '../../data/struct';

interface Props {
	statType: PlayerStatType;
	level: number;
}

// 레벨만 대충..... 경험치 획득량 표기를 구현하려면, ui context 를 만들어야 한다.... 아님 역산을 하든...
export const StatSnackbar: React.FC<Props> = props => {
	const { statType, level } = props;

	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		if (level !== (DefaultData.player.stat.find(x => x.key === statType)?.level ?? level))
			setOpen(true);
	}, [statType, level]);

	const locale = LocaleData.player.stat.in.find(x => x.key === statType)?.locale ?? 'TBD';

	return (
		<Snackbar
			open={open}
			onClose={handleClose}
			autoHideDuration={3000}
		>
			<Alert severity='info'>{`${locale} up to LV.${level} `}</Alert>
		</Snackbar>
	);
};
