import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from 'react';
import { DefaultData, MasterData } from '../data/static';
import {
	WorkFlowData,
	FlowType,
	ActivityUnlockCondition,
	PlayerData,
	PlayerStatType,
	PlayerUpgradeType,
} from '../data/struct';
import {
	DataAction,
	DataActionType,
	FlowActionType,
	FlowReducer,
} from '../logic';
import {
	DataContext,
	FlowContext,
} from './context';

interface Props {
	children: JSX.Element,
}

export const FlowContextProvider: React.FC<Props> = ({ children }: Props) => {
	const { store, dispatcher } = useContext(DataContext);
	const [flowContext, flowDispatcher] = useReducer(FlowReducer, DefaultData.flow, undefined);

	// work flow
	useEffect(() => {
		const clearFunc = execute_workFlow(flowContext.workFlow, dispatcher);
		return clearFunc;
	}, [flowContext, dispatcher, flowContext.workFlow.flowType]);

	// resume start
	useEffect(() => {
		// 이미 돌고있는 이력서가 있으면 스킵
		if (flowContext.resumeFlow.flowType !== FlowType.None) { return; }

		const resume = MasterData.resumeData.find(x => x.step === store.resume + 1);
		// 더이상 이력서 없으면 스킵
		if (!resume) { return; }

		// 잠겨있으면 스킵
		const isUnlocked = unlockCheck(resume.unlockConditions, store);
		if (!isUnlocked) { return; }

		flowDispatcher({
			actionType: FlowActionType.ResumeOn,
			data: {
				flowType: FlowType.Resume,
				data: resume,
				startTime: Date.now(),
			},
		});
	}, [store, flowContext.resumeFlow.flowType]);

	// after resume get
	useEffect(() => {
		flowDispatcher({
			actionType: FlowActionType.ResumeOff,
		});
	}, [store.resume]);

	return (
		<FlowContext.Provider value={{ flowContext, flowDispatcher }}>
			{children}
		</FlowContext.Provider>
	);
};

function execute_workFlow(
	workFlow: WorkFlowData,
	dispatcher: (a: DataAction) => void
) {
	switch (workFlow.flowType) {
		case FlowType.None:
			return () => { };
		case FlowType.SelfDevelopment: {
			const { data } = workFlow;
			const timer = window.setInterval(() => {
				dispatcher({
					actionType: DataActionType.SelfDevelopment,
					statType: data.type,
					cost: data.cost,
					reward: data.reward,
					extraRewards: data.extraRewards,
				});
			}, data.cost.time * 1000);
			return () => {
				window.clearInterval(timer);
			};
		}
		case FlowType.Economic: {
			const { data } = workFlow;
			const timer = window.setInterval(() => {
				dispatcher({
					actionType: DataActionType.Economic,
					cost: data.cost,
					reward: data.reward,
				});
			}, data.cost.time * 1000);
			return () => {
				window.clearInterval(timer);
			};
		}
	}
}

function unlockCheck(unlockConditions: ActivityUnlockCondition[], data: PlayerData): boolean {
	const conditionResults = unlockConditions.map(condition => {
		switch (condition.condition) {
			case ActivityUnlockCondition.Type.SPEECH_LEVEL:
				const speechLevel = data.stat.find(x => x.key === PlayerStatType.SPEECH)?.level ?? 0;
				return condition.value <= speechLevel;
			case ActivityUnlockCondition.Type.COMPETENCE_LEVEL:
				const competenceLevel = data.stat.find(x => x.key === PlayerStatType.COMPETENCE)?.level ?? 0;
				return condition.value <= competenceLevel;
			case ActivityUnlockCondition.Type.PHYSICAL_LEVEL:
				const physicalLevel = data.stat.find(x => x.key === PlayerStatType.PHYSICAL)?.level ?? 0;
				return condition.value <= physicalLevel;
			case ActivityUnlockCondition.Type.CHARM_LEVEL:
				const charmLevel = data.stat.find(x => x.key === PlayerStatType.CHARM)?.level ?? 0;
				return condition.value <= charmLevel;
			case ActivityUnlockCondition.Type.INT_MAX_UPGRADE:
				const intMaxUpgrade = data.upgrade.find(x => x.key === PlayerUpgradeType.INT_MAX)?.value ?? 1;
				return condition.value <= intMaxUpgrade;
		}
		return undefined;
	}).filter(x => x !== undefined);
	return _.every(conditionResults);
}
