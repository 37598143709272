import { Box, Grid, LinearProgress, SxProps, Theme, Typography } from '@mui/material';

const sx_grid: SxProps<Theme> = {
	width: '100%',
	justifyContent: 'flex,-start',
	alignItems: 'center',
};

const sx_name: SxProps<Theme> = {
	width: '15%',
};

const sx_progress: SxProps<Theme> = {
	width: '50%',
};

const sx_lv: SxProps<Theme> = {
	width: '35%',
	textAlign: 'right',
};

interface Props {
	name: string;
	int: number;
	maxInt: number;
}

export const IntDisplay: React.FC<Props> = (props) => {
	const { int, maxInt, name } = props;

	const intStr = (int / 100).toFixed(2);
	const maxIntStr = (maxInt / 100).toFixed(2);

	return (
		<Grid container sx={sx_grid}>
			<Box sx={sx_name}>
				<Typography>{name}</Typography>
			</Box>

			<Box sx={sx_progress}>
				<LinearProgress
					variant='determinate'
					sx={{ '& .MuiLinearProgress-bar': { transition: int === 0 ? 'none' : 'transform 0.2s linear' } }}
					value={100 * int / maxInt}
				/>
			</Box>

			<Box sx={sx_lv}>
				<Typography>{`(${intStr}/${maxIntStr})`}</Typography>
			</Box>
		</Grid >
	);
};
