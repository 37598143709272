import { PlayerData, PlayerStatData, ResumeActivityData } from '../../../data/struct';
import { DataActionType } from './DataActionType';

export interface DataAction_Resume {
	actionType: DataActionType.Resume;
	resumeData: ResumeActivityData;
}
export namespace DataAction_Resume {
	export function Do(data: PlayerData, action: DataAction_Resume): PlayerData {
		const rand = Math.random();
		const isSuccess = rand <= calc(data.stat, action.resumeData);

		const resume = isSuccess ? data.resume + 1 : data.resume;
		return { ...data, resume };
	}

	function calc(stats: PlayerStatData[], resume: ResumeActivityData) {
		const a = stats.map(x => {
			const data = resume.goal.find(y => `${y.type}` === x.key);
			const value = data?.value!;

			return Math.min(Math.sqrt(x.level / value), 1);
		}).reduce((a, b) => { return a + b; }, 0);
		return a / 4;
	}
}
