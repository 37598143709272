import { useContext } from 'react';
import { MasterData } from '../../data/static';
import { FlowType } from '../../data/struct';
import { DataContext, FlowContext } from '../../system';
import { ResumeActivityLockedCard } from './ResumeActivityLockedCard';
import { ResumeActivityExposeCard } from './ResumeActivityExposeCard';
import { ResumeActivityCoolDownCard } from './ResumeActivityCoolDownCard';

export const ResumeActivityPanel: React.FC = () => {
	const { store } = useContext(DataContext);
	const { flowContext } = useContext(FlowContext);
	const { resumeFlow } = flowContext;

	if (resumeFlow.flowType === FlowType.None) {
		const resumeData = MasterData.resumeData.find(x => x.step === store.resume + 1);
		if (!resumeData) { return (<></>); }
		return (<ResumeActivityLockedCard resumeData={resumeData} />);
	}

	const { data, startTime } = resumeFlow;

	const now = Date.now();
	const dt = now - startTime;
	const dtRemain = dt % (data.exposeTime + data.coolTime);
	const isActive = dtRemain < data.exposeTime;

	if (isActive) {
		return (<ResumeActivityExposeCard resumeData={data} startTime={now - dtRemain} />);
	} else {
		return (<ResumeActivityCoolDownCard resumeData={data} startTime={now - (dtRemain - data.exposeTime)} />);
	}
};
