import React, { useContext } from 'react';
import {
	Card,
	Grid,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import { LocaleData, MasterData } from '../../data/static';
import { DataContext } from '../../system';
import { CertificateDisplay } from './atom';
import { ResumeDisplay } from './atom/ResumeDisplay';

const sx_resource: SxProps<Theme> = {
	padding: '7px',
	border: '3px solid skyblue',
	borderRadius: '10px',
	margin: '4px',
	width: 372,
	minHeight: 200,
	textAlign: 'left',
};

export const MainSideBarCertCard: React.FC = () => {
	const { store } = useContext(DataContext);
	const { certificate, resume } = store;

	const certificateDataList = MasterData.certificateData.filter(x => certificate.includes(x.id));
	const resumeData = MasterData.resumeData.find(x => x.step === resume);

	return (
		<Card sx={sx_resource}>
			<Typography variant='h6'>
				{LocaleData.player.certificate.locale}
			</Typography>
			<Grid container>
				{certificateDataList.map(data => {
					return (
						<CertificateDisplay key={`side_cert_${data.id}`}
							name={data.name}
						/>
					);
				})}
			</Grid>
			<br />
			<Typography variant='h6'>
				{LocaleData.player.resume.locale}
			</Typography>
			<ResumeDisplay name={resumeData?.name ?? '백수'} />
		</Card>
	);
};
