import _ from 'lodash';
import { PlayerData } from '../../../data/struct';
import { DataActionType } from './DataActionType';

export interface DataAction_PlayTimeUpdate {
	actionType: DataActionType.PlayTimeUpdate;
	time: number;
}
export namespace DataAction_PlayTimeUpdate {
	export function Do(data: PlayerData, action: DataAction_PlayTimeUpdate): PlayerData {
		const clone = _.cloneDeep(data.info);
		clone.playTime = action.time;
		return { ...data, info: clone };
	}
}

