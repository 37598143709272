import {
	Box,
	Card,
	Grid,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import { LocaleData } from '../../data/static';
import { ResumeActivityData } from '../../data/struct';

const sx_card: SxProps<Theme> = {
	border: '4px solid cornflowerblue',
	margin: '6px',
	width: '672px',
};

const sx_card_head: SxProps<Theme> = {
	width: '90%',
};

const sx_card_content: SxProps<Theme> = {
	padding: '8px',
	width: '456px',
};

interface Props {
	resumeData: ResumeActivityData;
}

export const ResumeActivityLockedCard: React.FC<Props> = props => {
	const { resumeData } = props;

	return (
		<Card sx={sx_card}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
			>
				<Box sx={sx_card_head}>
					<Typography variant='h4'>{`다음 꿈의 기업: ${resumeData.name}`}</Typography>
				</Box>
				<br />
				<Box sx={sx_card_content}>
					<Typography variant='h5'>공채 응시 자격</Typography>
					{resumeData.unlockConditions.map((unlockCondition, i) => {
						const found = LocaleData.activityUnlockCondition.find(x => x.condition === unlockCondition.condition);
						return (
							<Typography key={i} color='red'>
								{`${found?.locale ?? 'TBD'} ${unlockCondition.value} 필요`}
							</Typography>
						);
					})}
				</Box>
			</Grid>
		</Card>
	);
};
