import { MasterData } from '../../../data/static';
import { PlayerStatData, PlayerStatType } from '../../../data/struct';

export class StatHelper {
	constructor(
		private readonly clone_stat: PlayerStatData[],
	) { }

	public inc(key: PlayerStatType, increment: number): void {
		const found = this.findOrCreate(key);
		found.exp += increment;

		this.levelupCheck(found);
	}

	public get() {
		return this.clone_stat;
	}

	private findOrCreate(key: PlayerStatType): PlayerStatData {
		const found = this.clone_stat.find(x => x.key === key);
		if (found !== undefined) { return found; }

		const skel: PlayerStatData = {
			key,
			level: 1,
			exp: 0,
		};
		this.clone_stat.push(skel);
		return skel;
	}

	private levelupCheck(data: PlayerStatData) {
		const maxExp = MasterData.expDataGenerator(data.level);

		if (data.exp < maxExp) { return; }

		data.exp -= maxExp;
		data.level++;

		this.levelupCheck(data);
	}
}
