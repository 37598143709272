export namespace TitleTable {
	interface Schema {
		title: string;
		minLevelSum: number;
	}

	export const table: Schema[] = [
		{ minLevelSum: 0, title: '갓생을 꿈꾸는 미생' },
		{ minLevelSum: 6, title: '사람 구실은 하는' },
		{ minLevelSum: 12, title: '골고루 올리는거 맞죠?' },
		{ minLevelSum: 12, title: '스펙은 편식하면 안됩니다.' },
		{ minLevelSum: 16, title: '스펙 콜렉터' },
		{ minLevelSum: 20, title: '스펙은 나의 힘' },
		{ minLevelSum: 30, title: '이젠 스펙에 진심' },
		{ minLevelSum: 40, title: '몸도 마음도 부자' },
		{ minLevelSum: 50, title: '부장님보다 스펙좋음' },
		{ minLevelSum: 100, title: '일론 머스크보다 똑똑함' },
	];
}
