import { FlowData } from '../../data/struct';
import {
	FlowAction,
	FlowActionType,
	FlowAction_ResumeOff,
	FlowAction_ResumeOn,
	FlowAction_WorkChange,
} from './action';

export const FlowReducer = (data: FlowData, action: FlowAction): FlowData => {
	// 왜인지 두번씩 불린다. idempotent 하게 돌게 만들기 위해서 무지성으로 deep clone
	// https://github.com/facebook/react/issues/16295
	// 순수함수여야 한다고 함.
	// 그래서 일단 deep clone 유지하는게 맞을듯?

	switch (action.actionType) {
		case FlowActionType.WorkChange:
			return FlowAction_WorkChange.Do(data, action);
		case FlowActionType.ResumeOff:
			return FlowAction_ResumeOff.Do(data, action);
		case FlowActionType.ResumeOn:
			return FlowAction_ResumeOn.Do(data, action);
		default:
			throw new Error('not implemented');
	}
};
