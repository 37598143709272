import { PlayerData, PlayerStatType, PlayerUpgradeType } from '../struct';
import { FlowType, FlowData } from '../struct';

/** @summary 기본 데이터 */
export namespace DefaultData {
	/** @summary 플레이어 기본 데이터 */
	export const player: PlayerData = {
		info: {
			playTime: 0,
		},
		resource: {
			intellect: 0,
			money: 0,
		},
		stat: [
			{ key: PlayerStatType.SPEECH, level: 1, exp: 0 },
			{ key: PlayerStatType.COMPETENCE, level: 1, exp: 0 },
			{ key: PlayerStatType.PHYSICAL, level: 1, exp: 0 },
			{ key: PlayerStatType.CHARM, level: 1, exp: 0 },
		],
		upgrade: [
			{ key: PlayerUpgradeType.INT_GEN, value: 1, },
			{ key: PlayerUpgradeType.INT_MAX, value: 1, },

			{ key: PlayerUpgradeType.SPEECH, value: 1, },
			{ key: PlayerUpgradeType.COMPETENCE, value: 1, },
			{ key: PlayerUpgradeType.PHYSICAL, value: 1, },
			{ key: PlayerUpgradeType.CHARM, value: 1, },
		],
		selfDevelopment: [
			{ key: PlayerStatType.SPEECH, step: 1 },
			{ key: PlayerStatType.COMPETENCE, step: 1 },
			{ key: PlayerStatType.PHYSICAL, step: 1 },
			{ key: PlayerStatType.CHARM, step: 1 },
		],
		economic: [1],
		certificate: [],
		resume: 0,
	};

	export const flow: FlowData = {
		resumeFlow: {
			flowType: FlowType.None,
		},
		workFlow: {
			flowType: FlowType.None,
		},
	};
}
