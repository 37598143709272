import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { AssignmentInd } from '@mui/icons-material';

export const RootBar: React.FC = () => {

	return (
		<AppBar position='static' color='primary' >
			<Toolbar >
				<AssignmentInd fontSize='large' sx={{ padding: 1 }}></AssignmentInd>
				<Typography variant='h3' color='inherit' component='div' sx={{ padding: 1 }}>
					Spec-Idle
				</Typography>
			</Toolbar>
		</AppBar>
	);
};


