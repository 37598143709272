import { Grid, Paper } from '@mui/material';
import { MasterData } from '../../data/static';
import { EconomicActivityCard } from './EconomicActivityCard';

export const EconomicActivityPanel: React.FC = () => {
	const activityList = MasterData.economicActivityData;

	return (
		<Paper
			sx={{
				padding: '0px',
				margin: '6px',
				maxWidth: '1000px',
			}}
			elevation={4}
		>
			<Grid container>
				{activityList.map((activity, i) => {
					return (
						<EconomicActivityCard key={i} activity={activity} />
					);
				})}
			</Grid>
		</Paper>
	);
};
