import {
	Box,
	Button,
	Grid,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';

const sx_grid_outer: SxProps<Theme> = {
	width: '100%',
	justifyContent: 'space-between',
	alignItems: 'center',
};

const sx_box: SxProps<Theme> = {
	width: '60%',
};

const sx_button: SxProps<Theme> = {
	justifyContent: 'flex-end',
	width: '40%',
};

const sx_name: SxProps<Theme> = {
};

const sx_step: SxProps<Theme> = {
	textalign: 'right',
};

interface Props {
	name: string;
	step: number;
	cost: string;
	onClick?: () => void;
}

export const ResourceManageButton: React.FC<Props> = (props) => {
	const { cost, name, onClick, step } = props;

	return (
		<Grid container sx={sx_grid_outer}>
			<Box sx={sx_box}>
				<Box sx={sx_name}>
					<Typography fontSize='18px'>{name}</Typography>
				</Box>
				<Box sx={sx_step}>
					<Typography fontSize='12px'>{`level: ${step}`}</Typography>
				</Box>
			</Box>
			<Grid container sx={sx_button}>
				<Button
					variant='outlined'
					onClick={onClick}
				>
					{cost}
				</Button>
			</Grid>
		</Grid>
	);
};
