import _ from 'lodash';
import { MasterData } from '../../../data/static';
import { PlayerData, PlayerUpgradeType } from '../../../data/struct';
import { ResourceHelper } from '../helper';
import { DataActionType } from './DataActionType';

export interface DataAction_IntellectGenerate {
	actionType: DataActionType.IntellectGenerate;
	amount: number;
}
export namespace DataAction_IntellectGenerate {
	export function Do(data: PlayerData, action: DataAction_IntellectGenerate): PlayerData {
		const upgrade = data.upgrade.find(x => x.key === PlayerUpgradeType.INT_GEN)!.value ?? 1;
		const multiple = MasterData.intGenTable.find(x => x.upgrade === upgrade)?.gen ?? 1;

		const clone = _.cloneDeep(data.resource);
		const resourceHelper = new ResourceHelper(clone, data.upgrade);
		resourceHelper.incInt(action.amount * multiple);

		return { ...data, resource: resourceHelper.get() };
	}
}
