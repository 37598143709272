import { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	Grid,
	LinearProgress,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import { FlowType, PlayerStatData, ResumeActivityData } from '../../data/struct';
import { DataContext, FlowContext } from '../../system';
import { DataActionType, FlowActionType } from '../../logic';

const sx_card: SxProps<Theme> = {
	padding: '12px',
	border: '4px solid cornflowerblue',
	margin: '6px',
	width: '672px',
};

const sx_card_button: SxProps<Theme> = {
	padding: '12px',
	border: '3px solid skyblue',
	borderRadius: 3,
	margin: '8px',
	width: '200px',
	height: '60px',
};

const sx_card_head: SxProps<Theme> = {
	width: '90%',
};

const sx_card_progress: SxProps<Theme> = {
	width: '90%',
};

interface Props {
	resumeData: ResumeActivityData;
	startTime: number;
}

export const ResumeActivityExposeCard: React.FC<Props> = props => {
	const { store, dispatcher } = useContext(DataContext);
	const { flowDispatcher } = useContext(FlowContext);
	const { resumeData, startTime } = props;
	const maxProgress = resumeData.exposeTime;
	const [progress, setProgress] = useState(Date.now() - startTime);

	const probability = (Math.round(calc(store.stat, resumeData) * 10000) / 100).toFixed(2);

	const onClick = () => {
		dispatcher({
			actionType: DataActionType.Resume,
			resumeData,
		});
		flowDispatcher({
			actionType: FlowActionType.ResumeOn,
			data: {
				flowType: FlowType.Resume,
				data: resumeData,
				startTime: Date.now() - resumeData.exposeTime,
			}
		});
	};

	useEffect(() => {
		const tick = 100;

		const timer = setInterval(() => {
			setProgress(old => {
				return Math.min(old + tick, maxProgress);
			});
		}, tick);

		return () => {
			clearInterval(timer);
		};
	}, [maxProgress]);

	return (
		<Card sx={sx_card}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
			>
				<Box sx={sx_card_head}>
					<Typography variant='h4'>{`기업 명: ${resumeData.name}`}</Typography>
					<Typography variant='h6'>공개 채용 진행 중</Typography>
				</Box>
				<Box sx={sx_card_progress}>
					<LinearProgress
						variant='determinate'
						sx={{ '& .MuiLinearProgress-bar': { transition: progress === 0 ? 'none' : 'transform 0.2s linear' } }}
						value={Math.floor((1 - (progress / maxProgress)) * 100)}
					/>
				</Box>
				<Box sx={sx_card_head}>
					<Typography textAlign='right'>{`본사는 모든 능력이 LV.${resumeData.goal[0].value}만큼 뛰어난 가지는 인재를 찾습니다.`}</Typography>
					<Typography textAlign='right' variant='h6'>{`예상 합격 확률: ${probability}%`}</Typography>
				</Box>
				<Button sx={sx_card_button} onClick={onClick}>
					지원하기
				</Button>
			</Grid>
		</Card>
	);

	function calc(stats: PlayerStatData[], resume: ResumeActivityData) {
		const a = stats.map(x => {
			const data = resume.goal.find(y => `${y.type}` === x.key);
			const value = data?.value!;

			return Math.min(Math.sqrt(x.level / value), 1);
		}).reduce((a, b) => { return a + b; }, 0);
		return a / 4;
	}
};
