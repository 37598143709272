import { useContext } from 'react';
import { Typography } from '@mui/material';
import {
	EconomicActivityData,
	FlowType,
} from '../../data/struct';
import { DataActionType, FlowActionType } from '../../logic';
import { DataContext, FlowContext } from '../../system';
import { ActivityCard, ActivityLockedCard } from './atom';

interface Props {
	activity: EconomicActivityData;
}

export const EconomicActivityCard: React.FC<Props> = (props) => {
	const { activity } = props;
	const { store: data, dispatcher } = useContext(DataContext);
	const { flowContext, flowDispatcher } = useContext(FlowContext);
	const { workFlow } = flowContext;

	const isUnlocked = data.economic.includes(activity.upgrade);

	if (!isUnlocked) {
		const onUnlock = () => {
			dispatcher({
				actionType: DataActionType.Unlock_Economic,
				step: activity.upgrade,
				consumption: activity.unlockCost.cash,
			});
		};
		return (
			<ActivityLockedCard
				unlockConditions={activity.unlockConditions}
				unlockCostCash={activity.unlockCost.cash}
				onUnlock={onUnlock}
			/>
		);
	}

	const isWork = workFlow.flowType === FlowType.Economic
		&& workFlow.data.upgrade === activity.upgrade;

	const onClick = () => {
		flowDispatcher({
			actionType: FlowActionType.WorkChange,
			data: isWork
				? { flowType: FlowType.None }
				: {
					flowType: FlowType.Economic,
					data: activity,
					startTime: Date.now(),
				},
		});
	};

	return (
		<ActivityCard
			buttonName={activity.name}

			isActive={true}
			isWork={isWork}

			startTime={workFlow.flowType === FlowType.Economic ? workFlow.startTime : 0}
			workTime={activity.cost.time}

			displayChildren={
				<Typography>{`돈 획득: ${activity.reward.amount} 원`}</Typography>
			}
			onClick={onClick}
		/>
	);
};
