import { MasterData } from '../../../data/static';
import { PlayerUpgradeData, PlayerUpgradeType } from '../../../data/struct';

export class UpgradeHelper {
	constructor(
		private readonly clone_upgrade: PlayerUpgradeData[],
	) { }

	public upgrade(key: PlayerUpgradeType): void {
		let found = this.clone_upgrade.find(x => x.key === key);
		if (!found) {
			found = { key, value: 1 };
			this.clone_upgrade.push(found);
		}

		const gradeList = MasterData.upgradeTable.filter(x => x.type === key).map(x => x.grade);
		if (gradeList.includes(found.value + 1)) {
			found.value++;
		}
	}

	public get() {
		return this.clone_upgrade;
	}
}
