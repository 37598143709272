import { useEffect, useState } from 'react';
import {
	Box,
	Card,
	Grid,
	LinearProgress,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import { ResumeActivityData } from '../../data/struct';

const sx_card: SxProps<Theme> = {
	padding: '12px',
	border: '4px solid cornflowerblue',
	margin: '6px',
	width: '472px',
};

const sx_card_head: SxProps<Theme> = {
	width: '90%',
};

const sx_card_progress: SxProps<Theme> = {
	width: '90%',
};

interface Props {
	resumeData: ResumeActivityData;
	startTime: number;
}

export const ResumeActivityCoolDownCard: React.FC<Props> = props => {
	const { resumeData, startTime } = props;
	const maxProgress = resumeData.coolTime;
	const [progress, setProgress] = useState(Date.now() - startTime);

	useEffect(() => {
		const tick = 100;

		const timer = setInterval(() => {
			setProgress(old => {
				return Math.min(old + tick, maxProgress);
			});
		}, tick);

		return () => {
			clearInterval(timer);
		};
	}, [maxProgress]);

	return (
		<Card sx={sx_card}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
			>
				<Box sx={sx_card_head}>
					<Typography variant='h4'>{`기업 명: ${resumeData.name}`}</Typography>
					<Typography variant='h6'>다음 공개 채용 대기 중</Typography>
				</Box>
				<Box sx={sx_card_progress}>
					<LinearProgress
						variant='determinate'
						sx={{ '& .MuiLinearProgress-bar': { transition: progress === 0 ? 'none' : 'transform 0.2s linear' } }}
						value={(() => {
							const a = Math.floor((progress / maxProgress) * 100);
							console.log(`${progress}-${maxProgress}-${a}`);
							return a;
						})()}
					/>
				</Box>
			</Grid>
		</Card>
	);
};
