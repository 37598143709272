import _ from 'lodash';
import {
	ActivityCost,
	ActivityReward,
	PlayerData,
} from '../../../data/struct';
import { ResourceHelper } from '../helper';
import { DataActionType } from './DataActionType';

export interface DataAction_Economic {
	actionType: DataActionType.Economic;
	cost: ActivityCost;
	reward: ActivityReward;
}
export namespace DataAction_Economic {
	export function Do(data: PlayerData, action: DataAction_Economic): PlayerData {
		// 전처리
		const clone = _.cloneDeep(data);

		// 소모
		const isSuccess = consume(clone, action.cost);
		if (!isSuccess) { return data; }

		// 증가
		reward(clone, action);

		return clone;
	}

	function consume(
		clone: PlayerData,
		cost: ActivityCost,
	): boolean {
		const resourceHelper = new ResourceHelper(clone.resource, clone.upgrade);
		const isSuccess_int = resourceHelper.decInt(cost.int);
		const isSuccess_money = resourceHelper.decMoney(cost.cash);

		return isSuccess_int && isSuccess_money;
	}

	function reward(
		clone: PlayerData,
		action: DataAction_Economic,
	): void {
		const resourceHelper = new ResourceHelper(clone.resource, clone.upgrade);
		resourceHelper.incMoney(action.reward.amount);
	}
}
