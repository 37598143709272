import { useContext } from 'react';
import { Typography } from '@mui/material';
import { MasterData } from '../../data/static';
import {
	FlowType,
	SelfDevelopActivityData,
} from '../../data/struct';
import { DataActionType, FlowActionType } from '../../logic';
import { DataContext, FlowContext } from '../../system';
import { ActivityCard, ActivityLockedCard } from './atom';

interface Props {
	activity: SelfDevelopActivityData;
}

export const SelfDevelopActivityCard: React.FC<Props> = (props) => {
	const { activity } = props;
	const { store: data, dispatcher } = useContext(DataContext);
	const { flowContext, flowDispatcher } = useContext(FlowContext);
	const { workFlow } = flowContext;

	const isUnlocked = data.selfDevelopment.find(x => x.key === activity.type && x.step === activity.step) !== undefined;

	if (!isUnlocked) {
		const onUnlock = () => {
			dispatcher({
				actionType: DataActionType.Unlock_SelfDev,
				statType: activity.type,
				step: activity.step,
				consumption: activity.unlockCost.cash,
			});
		};
		return (
			<ActivityLockedCard
				unlockConditions={activity.unlockConditions}
				unlockCostCash={activity.unlockCost.cash}
				onUnlock={onUnlock}
			/>
		);
	}

	const isWork = workFlow.flowType === FlowType.SelfDevelopment
		&& workFlow.data.type === activity.type
		&& workFlow.data.step === activity.step;

	const resume = MasterData.resumeData.find(x => x.step === data.resume);
	const upgrade = data.upgrade.find(x => x.key === `${activity.type}`)?.value ?? 1;
	const upgardeBonus = MasterData.statBonusTable.find(x => x.upgrade === upgrade)?.bonus ?? 0;

	const bonusAmount = (resume?.reward.amount ?? 0) + upgardeBonus;
	const bonus = bonusAmount !== 0 ? `+ ${bonusAmount}` : '';

	const onClick = () => {
		flowDispatcher({
			actionType: FlowActionType.WorkChange,
			data: isWork
				? { flowType: FlowType.None }
				: {
					flowType: FlowType.SelfDevelopment,
					data: activity,
					startTime: Date.now(),
				},
		});
	};

	return (
		<ActivityCard
			buttonName={activity.name}

			isActive={true}
			isWork={isWork}

			startTime={workFlow.flowType === FlowType.SelfDevelopment ? workFlow.startTime : 0}
			workTime={activity.cost.time}

			displayChildren={
				<>
					<Typography>{`exp 획득: ${activity.reward.amount} ${bonus}`}</Typography>
					<Typography>{`지능 소모: ${(activity.cost.int / 100).toFixed(0)}\n 비용: ${activity.cost.cash} 원`}</Typography>
				</>
			}
			onClick={onClick}
		/>
	);
};
