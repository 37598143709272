import { FlowData, FlowType } from '../../../data/struct';
import { FlowActionType } from './FlowActionType';

export interface FlowAction_ResumeOff {
	actionType: FlowActionType.ResumeOff,
}
export namespace FlowAction_ResumeOff {
	export function Do(data: FlowData, action: FlowAction_ResumeOff): FlowData {
		return { ...data, resumeFlow: { flowType: FlowType.None } };
	}
}
