import { PlayerStatType } from './PlayerStatData';

export interface ActivityUnlockCondition {
	condition: ActivityUnlockCondition.Type,
	value: number,
}
export namespace ActivityUnlockCondition {
	export enum Type {
		INT_MAX_UPGRADE,
		SPEECH_LEVEL,
		COMPETENCE_LEVEL,
		PHYSICAL_LEVEL,
		CHARM_LEVEL,
	}
}

export interface ActivityCost {
	time: number;
	int: number;
	cash: number;
}

export interface ActivityReward {
	amount: number;
}

export interface ActivityExtraReward {
	type: ActivityExtraReward.Type;
	amount: number;
	rate: number;
}
export namespace ActivityExtraReward {
	export enum Type {
		SPEECH = 'SPEECH',
		COMPETENCE = 'COMPETENCE',
		PHYSICAL = 'PHYSICAL',
		CHARM = 'CHARM',
	}
}

export interface ActivityGoal {
	type: ActivityGoal.Type;
	value: number;
}
export namespace ActivityGoal {
	export enum Type {
		SPEECH = 'SPEECH',
		COMPETENCE = 'COMPETENCE',
		PHYSICAL = 'PHYSICAL',
		CHARM = 'CHARM',
	}
}

export interface SelfDevelopActivityData {
	type: PlayerStatType;
	step: number;
	name: string;

	unlockConditions: ActivityUnlockCondition[];
	unlockCost: ActivityCost;

	cost: ActivityCost;
	reward: ActivityReward;
	extraRewards: ActivityExtraReward[];
}

export interface EconomicActivityData {
	upgrade: number; //step
	name: string;

	unlockConditions: ActivityUnlockCondition[];
	unlockCost: ActivityCost;

	cost: ActivityCost;
	reward: ActivityReward;
}

export interface CertificateActivityData {
	id: number;
	name: string;
	summary: string;
	unlockConditions: ActivityUnlockCondition[];
}

export interface ResumeActivityData {
	step: number;
	name: string;
	unlockConditions: ActivityUnlockCondition[];
	exposeTime: number;
	coolTime: number;
	goal: ActivityGoal[];
	reward: ActivityReward;
}
