import {
	EconomicActivityData,
	ResumeActivityData,
	SelfDevelopActivityData,
} from './ActivityData';

export enum FlowType {
	None,
	SelfDevelopment,
	Economic,
	Resume,
}

export type FlowData = {
	workFlow: WorkFlowData;
	resumeFlow: ResumeFlowData
};

export type WorkFlowData = (
	| FlowData_None
	| FlowData_SelfDevelopment
	| FlowData_Economic
);

export type ResumeFlowData = (
	| FlowData_None
	| FlowData_Resume
);

interface FlowData_None {
	flowType: FlowType.None;
}

interface FlowData_SelfDevelopment {
	flowType: FlowType.SelfDevelopment;
	data: SelfDevelopActivityData;
	startTime: number;
}

interface FlowData_Economic {
	flowType: FlowType.Economic;
	data: EconomicActivityData;
	startTime: number;
}

interface FlowData_Resume {
	flowType: FlowType.Resume;
	data: ResumeActivityData;
	startTime: number;
}
