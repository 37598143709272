import React, { useEffect, useState } from 'react';
import { Box, Modal, SxProps, Theme, Typography } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { DefaultData } from '../../data/static';

const style: SxProps<Theme> = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	padding: 4,
	width: 400,
	minHeight: 240,
	backgroundColor: 'background.paper',
	border: '4px solid skyblue',
	borderRadius: '10px',
	boxShadow: 12,
	textAlign: 'center',
};

interface Props {
	resume: number;
}

export const ResumeModal: React.FC<Props> = props => {
	const { resume } = props;

	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		if (resume !== DefaultData.player.resume)
			setOpen(true);
	}, [resume]);

	return (
		<Modal
			open={open}
			onClose={handleClose}
		>
			<Box sx={style}>
				<AssignmentTurnedInIcon style={{ width: 360, height: 360, color: 'green' }} />
				<Typography variant='h6' component='h2'>공채 합격</Typography>
			</Box>
		</Modal>
	);
};
