import { FlowData, ResumeFlowData } from '../../../data/struct';
import { FlowActionType } from './FlowActionType';

export interface FlowAction_ResumeOn {
	actionType: FlowActionType.ResumeOn,
	data: ResumeFlowData;
}
export namespace FlowAction_ResumeOn {
	export function Do(data: FlowData, action: FlowAction_ResumeOn): FlowData {
		return { ...data, resumeFlow: action.data };
	}
}
