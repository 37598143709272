export namespace EconomicActivityTable {
	type UnlockType = (
		| 'INT_MAX_UPGRADE'
		| 'SPEECH_LEVEL'
		| 'COMPETENCE_LEVEL'
		| 'PHYSICAL_LEVEL'
		| 'CHARM_LEVEL'
	);

	type RewardType = (
		| 'SPEECH'
		| 'COMPETENCE'
		| 'PHYSICAL'
		| 'CHARM'
		| 'MONEY'
	);

	interface Schema {
		step: number;
		name: string;
		unlockCondition1: UnlockType | null;
		unlockConditionValue1: number;
		unlockCondition2: UnlockType | null;
		unlockConditionValue2: number;
		unlockCondition3: UnlockType | null;
		unlockConditionValue3: number;
		unlockCostCash: number;
		costTime: number;
		rewardType: RewardType; // 일단 안씀
		rewardValue: number;
	}

	export const table: Schema[] = [
		{
			step: 1, name: '편의점 알바 (3s)',
			unlockCondition1: null,
			unlockConditionValue1: 0,
			unlockCondition2: null,
			unlockConditionValue2: 0,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 0,
			costTime: 3,
			rewardType: 'MONEY',
			rewardValue: 3000,
		},
		{
			step: 2, name: '과외 (5s)',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 5,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 5,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			unlockCostCash: 1000,
			costTime: 5,
			rewardType: 'MONEY',
			rewardValue: 6000,
		},
		{
			step: 3, name: '레크레이션 강사 (7s)',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 10,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 7,
			unlockCondition3: 'CHARM_LEVEL',
			unlockConditionValue3: 7,
			unlockCostCash: 0,
			costTime: 7,
			rewardType: 'MONEY',
			rewardValue: 8000,
		},
		{
			step: 4, name: '헬스 트레이너 (10s)',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 10,
			unlockCondition2: 'PHYSICAL_LEVEL',
			unlockConditionValue2: 15,
			unlockCondition3: 'CHARM_LEVEL',
			unlockConditionValue3: 7,
			unlockCostCash: 0,
			costTime: 10,
			rewardType: 'MONEY',
			rewardValue: 12000,
		},
		{
			step: 5, name: '피팅 모델 (12s)',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 15,
			unlockCondition2: 'PHYSICAL_LEVEL',
			unlockConditionValue2: 17,
			unlockCondition3: 'CHARM_LEVEL',
			unlockConditionValue3: 20,
			unlockCostCash: 0,
			costTime: 12,
			rewardType: 'MONEY',
			rewardValue: 14000,
		},
		{
			step: 6, name: '고액 외국어 과외 (13s)',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 25,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 30,
			unlockCondition3: 'CHARM_LEVEL',
			unlockConditionValue3: 22,
			unlockCostCash: 0,
			costTime: 13,
			rewardType: 'MONEY',
			rewardValue: 16000,
		},
	];
}
