import { Box, Grid, LinearProgress, SxProps, Theme, Typography } from '@mui/material';

const sx_grid: SxProps<Theme> = {
	width: '100%',
	justifyContent: 'flex,-start',
	alignItems: 'center',
};

const sx_name: SxProps<Theme> = {
	width: '20%',
};

const sx_lv: SxProps<Theme> = {
	width: '15%',
};

const sx_progress: SxProps<Theme> = {
	width: '47%',
};

const sx_percent: SxProps<Theme> = {
	width: '18%',
	textAlign: 'right',
};

interface Props {
	name: string;
	level: number;
	exp: number;
	maxExp: number;
}

export const StatDisplay: React.FC<Props> = (props) => {
	const { exp, level, maxExp, name } = props;

	return (
		<Grid container sx={sx_grid}>
			<Box sx={sx_name}>
				<Typography>{name}</Typography>
			</Box>

			<Box sx={sx_lv}>
				<Typography>{`LV: ${level}`}</Typography>
			</Box>

			<Box sx={sx_progress}>
				<LinearProgress
					variant='determinate'
					sx={{ '& .MuiLinearProgress-bar': { transition: exp === 0 ? 'none' : 'transform 0.2s linear' } }}
					value={100 * exp / maxExp}
				/>
			</Box>

			<Box sx={sx_percent}>
				<Typography>{`${(Math.round(10000 * exp / maxExp) / 100).toFixed(2)}%`}</Typography>
			</Box>
		</Grid >
	);
};
