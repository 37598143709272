import React from 'react';
import { Grid } from '@mui/material';
import { MainTabPanel } from './component/MainTabPanel';
import { RootBar } from './component/RootBar';
import { MainSideBar } from './component/MainSideBar';
import { PopupCore } from './component/PopupCore';

export const App: React.FC = () => {
	return (
		<div>
			<PopupCore />
			<RootBar />
			<Grid
				container
				direction='row'
				justifyContent='flex-start'
				alignItems='stretch'
			>
				<MainSideBar />
				<MainTabPanel />
			</Grid>
		</div>
	);
};
