import { Box, Card, Grid, SxProps, Theme, Typography } from '@mui/material';

const sx_card: SxProps<Theme> = {
	padding: '8px',
	border: '3px solid darkgray',
	margin: '2px',
	width: 98,
	height: 40,
};

const sx_grid: SxProps<Theme> = {
	width: '100%',
	justifyContent: 'flex-start',
	alignItems: 'center',
};

const sx_name: SxProps<Theme> = {
	width: '100%',
};

interface Props {
	name: string;
}

export const CertificateDisplay: React.FC<Props> = (props) => {
	const { name } = props;

	return (
		<Card sx={sx_card}>
			<Grid container sx={sx_grid}>
				<Box sx={sx_name}>
					<Typography>{name}</Typography>
				</Box>
			</Grid>
		</Card>
	);
};
