export namespace ResumeActivityTable {
	type UnlockType = (
		| 'INT_MAX_UPGRADE'
		| 'SPEECH_LEVEL'
		| 'COMPETENCE_LEVEL'
		| 'PHYSICAL_LEVEL'
		| 'CHARM_LEVEL'
	);

	interface Schema {
		step: number;
		name: string;
		unlockCondition1: UnlockType | null;
		unlockConditionValue1: number;
		unlockCondition2: UnlockType | null;
		unlockConditionValue2: number;
		unlockCondition3: UnlockType | null;
		unlockConditionValue3: number;
		exposureTime: number;
		goalSpeech: number;
		goalCompetence: number;
		goalPhysical: number;
		goalCharm: number;
		rewardValue: number;
	}

	export const constants = {
		ReExposeTime: 300,
	};

	export const table: Schema[] = [
		{
			step: 1,
			name: '스터디 클럽',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 1,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 1,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			exposureTime: 60,
			goalSpeech: 1,
			goalCompetence: 1,
			goalPhysical: 1,
			goalCharm: 1,
			rewardValue: 1,
		},


		{
			step: 2,
			name: '아주 좋소 상사',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 5,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 5,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			exposureTime: 60,
			goalSpeech: 10,
			goalCompetence: 10,
			goalPhysical: 10,
			goalCharm: 10,
			rewardValue: 1,
		},


		{
			step: 3,
			name: '아주 좋소 상사',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 5,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 5,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			exposureTime: 30,
			goalSpeech: 10,
			goalCompetence: 10,
			goalPhysical: 10,
			goalCharm: 10,
			rewardValue: 1,
		},
		{
			step: 4,
			name: '어쩌다보니 중견회사',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 12,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 10,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			exposureTime: 60,
			goalSpeech: 20,
			goalCompetence: 20,
			goalPhysical: 20,
			goalCharm: 20,
			rewardValue: 2,
		},
		{
			step: 5,
			name: '쩜오 대기업',
			unlockCondition1: 'SPEECH_LEVEL',
			unlockConditionValue1: 15,
			unlockCondition2: 'COMPETENCE_LEVEL',
			unlockConditionValue2: 13,
			unlockCondition3: null,
			unlockConditionValue3: 0,
			exposureTime: 100,
			goalSpeech: 30,
			goalCompetence: 30,
			goalPhysical: 30,
			goalCharm: 30,
			rewardValue: 3,
		},

	];
}
