import _ from 'lodash';
import { MasterData } from '../../../data/static';
import {
	ActivityCost,
	ActivityExtraReward,
	ActivityReward,
	PlayerData,
	PlayerStatType,
} from '../../../data/struct';
import {
	ResourceHelper,
	StatHelper,
} from '../helper';
import { DataActionType } from './DataActionType';

export interface DataAction_SelfDevelopment {
	actionType: DataActionType.SelfDevelopment;
	statType: PlayerStatType;
	cost: ActivityCost;
	reward: ActivityReward;
	extraRewards: ActivityExtraReward[];
}
export namespace DataAction_SelfDevelopment {
	export function Do(data: PlayerData, action: DataAction_SelfDevelopment): PlayerData {
		// 전처리
		const clone = _.cloneDeep(data);

		// 소모
		const isSuccess = consume(clone, action.cost);
		if (!isSuccess) { return data; }

		// 증가
		reward(clone, action);
		extraReward(clone, action.extraRewards);

		return clone;
	}

	function consume(
		clone: PlayerData,
		cost: ActivityCost,
	): boolean {
		const resourceHelper = new ResourceHelper(clone.resource, clone.upgrade);
		const isSuccess_int = resourceHelper.decInt(cost.int);
		const isSuccess_money = resourceHelper.decMoney(cost.cash);

		return isSuccess_int && isSuccess_money;
	}

	function reward(
		clone: PlayerData,
		action: DataAction_SelfDevelopment,
	): void {
		let value = action.reward.amount;

		// 이력서 높아지면 영구적으로 스텟 성장치 올림
		const resume = MasterData.resumeData.find(x => x.step === clone.resume);
		if (!!resume) {
			value += resume.reward.amount;
		}

		// 해탈도 영구적으로 스텟 성장치를 올림
		const upgrade = clone.upgrade.find(x => x.key === `${action.statType}`)?.value ?? 1;
		const upgardeBonus = MasterData.statBonusTable.find(x => x.upgrade === upgrade)?.bonus ?? 0;
		if (!!upgrade) {
			// 임시로 레벨-1 만큼 올림
			value += upgardeBonus;
		}

		const statHelper = new StatHelper(clone.stat);
		statHelper.inc(action.statType, value);
	}

	function extraReward(
		clone: PlayerData,
		extraReward: ActivityExtraReward[],
	) {
		for (const extra of extraReward) {
			const rand = Math.random();
			if (rand <= extra.rate) {
				extraRewardInner(clone, extra);
			}
		}
	}

	function extraRewardInner(
		clone: PlayerData,
		extra: ActivityExtraReward,
	): void {
		switch (extra.type) {
			case ActivityExtraReward.Type.SPEECH:
				{
					const statHelper = new StatHelper(clone.stat);
					statHelper.inc(PlayerStatType.SPEECH, extra.amount);
					break;
				}
			case ActivityExtraReward.Type.COMPETENCE:
				{
					const statHelper = new StatHelper(clone.stat);
					statHelper.inc(PlayerStatType.COMPETENCE, extra.amount);
					break;
				}
			case ActivityExtraReward.Type.PHYSICAL:
				{
					const statHelper = new StatHelper(clone.stat);
					statHelper.inc(PlayerStatType.PHYSICAL, extra.amount);
					break;
				}
			case ActivityExtraReward.Type.CHARM:
				{
					const statHelper = new StatHelper(clone.stat);
					statHelper.inc(PlayerStatType.CHARM, extra.amount);
					break;
				}
		}
	}
}
